<template>
  <component
    :is="tag"
    class="btn"
    :class="[
      variant,
      isLoading ? loadingClass : '',
      size
    ]"
    :type="tag === 'button' ? 'button' : ''"
    :role="tag !== 'button' ? 'button' : ''"
    @click="handleClick"
  >
    <slot>
      <span
        v-if="textClass"
        :class="textClass"
      >
        {{ text }}
      </span>
      <template v-else>
        {{ text }}
      </template>
    </slot>
  </component>
</template>

<script>
export default {
    name: 'BaseButton',
    props: {
        tag: {
            type: String,
            default: 'button',
            description: 'Button tag (default -> button)'
        },
        variant: {
            type: String,
            default: '',
            description: 'Button variant(like btn-primary, btn-secondary etc.), ensure respective css class is added for the variant(check style block below)'
        },
        size: {
            type: String,
            default: 'py-2 px-2.5',
            description: 'Button size (use padding classes to control size)'
        },
        textClass: {
            type: String,
            default: '',
            description: 'css classes applied to button text'
        },
        text: {
            type: String,
            default: '',
            description: 'Button text in case not provided via default slot'
        },
        isLoading: {
            type: Boolean,
            default: false,
            description: 'triggers loading state of button if set to true'
        },
        loadingClass: {
            type: String,
            default: 'loading',
            description: 'css classes applied on button in loading state'
        }
    },

    emits: [
        'click'
    ],

    setup (props, { emit, slots, attrs }) {
        const handleClick = (evt) => {
            if (!props.isLoading) {
                emit('click', evt);
            }
        };
        return {
            handleClick,
            slots,
            attrs
        };
    }
};
</script>

<style scoped>
@layer components {
  /* default styling */
  .btn {
    @apply focus:outline-none cursor-pointer;
  }
  /* button variants */
  .btn-primary {
    @apply bg-primary-red text-white rounded-sm text-sm leading-4;
    &:disabled {
      @apply bg-primary-gray;
    }
  }
  .btn-blue {
    @apply text-black bg-custom-color-blue-login border-custom-color-dark-blue rounded-sm;
  }
  .btn-primary-alt {
    @apply bg-primary-red text-white rounded-sm text-sm leading-4;
    &:disabled {
      @apply opacity-100;
    }
  }
  .btn-gray {
    @apply bg-primary-gray text-white rounded-sm text-sm leading-4;
  }
  .btn-black {
    @apply bg-black text-white rounded-sm text-sm leading-4;
  }
  .btn-gray-2 {
    @apply bg-custom-gray-2 text-primary-red rounded-sm text-sm leading-4;
  }
  .btn-gray-5 {
    @apply bg-custom-gray-5 text-white rounded-sm text-sm leading-4;
  }
  .btn-green {
    @apply bg-custom-green-1 text-white rounded-sm text-sm leading-4;
  }
  .btn-border-gray-5 {
    @apply text-custom-gray-1 rounded-sm border border-custom-gray-5 text-sm leading-4;
  }
  .btn-border-gray-8 {
    @apply text-custom-gray-1 rounded-sm border border-custom-gray-8 text-sm leading-4;
  }
  .btn-link {
    @apply p-2 text-sm rounded-sm border bg-custom-gray-5 text-custom-gray-7 hover:text-primary-red hover:bg-custom-gray-2  leading-4;
  }

  /* button state */
  :disabled, .loading {
    @apply opacity-50 cursor-not-allowed;
  }
  .btn-border-white {
    @apply py-2 pl-2 bg-white text-black rounded-sm focus:outline-none text-sm leading-4;
  }
}
</style>
